import React, {useState, useRef, useContext, useEffect} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col, Spinner, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Redirect, useHistory, useParams} from "react-router-dom";

import logo from '../assets/logo.png';
import logoOn from '../assets/logo-on.png';
import AppContext from '../AppContext.js';
import PageURL from "../PageURL.js";
import {checkPasswordRules, postApiRequest, getApiRequest, getCookieByName} from "../Utils/Utils.js";

const PageLogin = function() {
	const history = useHistory();
	const {token} = useParams();
	const [tokenValid, setTokenValid] = useState(false);
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [repeatPasswordError, setRepeatPasswordError] = useState("");
	const {appState, setAppState} = useContext(AppContext);

  const forgotPasswordOnKeyDown = function(evt) {
    if (evt.keyCode == 13) handleSubmit(evt);
  };

	const handleSubmit = async function(evt) {
    evt.preventDefault();
    	//Do logic checks on fields like so
    let error = false;
    if (password === undefined || password.length < 14) {
      setRepeatPasswordError("Password must have more than 13 characters.");
      error = true;
    } else if (!checkPasswordRules(password)) {
      setRepeatPasswordError("Password must contain an upper case and lower case letter, a digit and a special character.");
    	error = true;
    } else if (password !== repeatPassword) {
    	setRepeatPasswordError("Password and repeat password do not match.");
    	error = true;
    } else setRepeatPasswordError("");

    	//If error is false, we send the request for login
    if (error == false) {
    	const repeatPasswordData = {
    		'password' : password,
    		'password_reset_token' : token
    	};
    	const response = await postApiRequest(JSON.stringify(repeatPasswordData), "user/reset-password/set-password");
    	if (response.status === 200) {
    		const responseData = await response.json();
    		setAppState({
    			loginMessage: "New password set successfully!"
    		});
    		history.push('/login');
    	} else {
    		setRepeatPasswordError("Please try again later.");
    	}
    }
  };

  useEffect(async function() {
    document.title = "VLBC - Forgot Password";
    const response = await getApiRequest("user/reset-password/check-token/"+token);
    if (response.status === 200) {
    	setTokenValid(true);
    } else {
    	history.push('/login');
    }
  }, []);

	return ( tokenValid &&
	<div className="login-page">
		<Row>
			<Col xl="3" className="login-content">
      	<div className="content d-flex align-items-center">
      	  <Row>
      	    <Col xs="12">
      	      <div className="logo-content mb-3"><img alt="logo" src={logo} /></div>
      	    </Col>
      	    <Col xs="12">
      	      <div className="text-content">
      	        Van Leeuwen is a globally operating trading company specialized in steel pipes, and pipe and tube applications. We are active in virtually all industrial sectors. With some forty branches spread throughout Europe, the Middle East, Asia, Australia and America, we ensure that materials are available anywhere in the world, customized wherever needed. Our employees have specialist knowledge of sourcing, project management, logistics and stock planning and work closely together with our customers in the Industry and Energy markets. The combination of global logistics and knowledge of products and customer applications makes Van Leeuwen a leading company in its markets.
      	      </div>
      	    </Col>
      	  </Row>
      	</div>
     	</Col>
     	<Col xl="9" className="login-form">
     		<div className="form d-flex flex-column justify-content-center align-items-center">
     			<Row className="mb-3">
     				<Col xs="12">
              <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
            </Col>
            <Col xs="12">
            	<Form onSubmit={handleSubmit} style={{maxWidth: '10000px'}}>
								<FormGroup>
									<Label className="form-control-label" for="new-password">New password</Label>
									<Input className={'form-control ' + (repeatPasswordError !== "" ? "is-invalid" : "")} id="new-password" type="password" placeholder="Enter your new password"
										value={password} onChange={e => setPassword(e.target.value)} onKeyDown={forgotPasswordOnKeyDown} />
								</FormGroup>
								<FormGroup>
									<Label className="form-control-label" for="repeat-new-password">Repeat new password</Label>
									<Input className={'form-control ' + (repeatPasswordError !== "" ? "is-invalid" : "")} id="repeat-new-password" type="password" placeholder="Repeat your new password"
										value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} onKeyDown={forgotPasswordOnKeyDown} />
									{repeatPasswordError !== "" && <div className="invalid-feedback">{repeatPasswordError}</div>}
								</FormGroup>
								<Row>
									<Col xs="12" style={{padding: 0}}>
										<Button type="submit" color="primary">Reset Password</Button>
									</Col>
								</Row>
							</Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
	</div>
	);
};

export default PageLogin;