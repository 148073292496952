import React, {useState, useEffect, Fragment, useContext} from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {Form, Row, Col, Button, Collapse, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, Container, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faEdit, faFilePdf, faPlus, faChevronDown, faChevronUp, faSearch} from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';

import AppContext from '../AppContext.js';
import CustomTable from '../Utils/CustomTable.js';
import {getApiRequest, postApiRequest, patchApiRequest} from "../Utils/Utils.js";

const PageCertificates = function() {
  const {appState, setAppState} = useContext(AppContext);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [certificateApiPath, setCertificateApiPath] = useState("certificates");
  const [certificateCountPath, setCertificateCountPath] = useState("certificates/count");
  const [modalAddFormErrors, setModalAddFormErrors] = useState({});
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalAddSubmitButtonDisabled, setModalAddSubmitButtonDisabled] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalDeleteSubmitButtonDisabled, setModalDeleteSubmitButtonDisabled] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalEditSubmitButtonDisabled, setModalEditSubmitButtonDisabled] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [salesOrder, setSalesOrder] = useState("");
  const [delivery, setDelivery] = useState("");
  const [customerReference, setCustomerReference] = useState("");
  const [lineReference, setLineReference] = useState("");
  const [item, setItem] = useState("");
  const [heatNumber, setHeatNumber] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [company, setCompany] = useState("");
  const [allSalesOrder, setAllSalesOrder] = useState([]);
  const [allDelivery, setAllDelivery] = useState([]);
  const [allCustomerReference, setAllCustomerReference] = useState([]);
  const [allLineReference, setAllLineReference] = useState([]);
  const [allItem, setAllItem] = useState([]);
  const [allHeatNumber, setAllHeatNumber] = useState([]);
  const [allCustomerCode, setAllCustomerCode] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [tableHeaderCheck, setTableHeaderCheck] = useState(false);
  const tableColumns = [
      //Text, Class
    [
    <FormGroup check>
      <Label>
        <Input style={{"top" : "0"}} type="checkbox" checked={tableHeaderCheck} onClick={(e) => tableHeaderCheckboxClicked(e)}/>{' '}
      </Label>
    </FormGroup>, ""],
    ["Company", ""],
    ["Customer Code", ""],
    ["Sales Order", ""],
    ["SO Line", ""],
    ["Delivery", ""],
    ["Del Line", ""],
    ["Del Doc Date", ""],
    ["Customer Reference", ""],
    ["Line Reference", ""],
    ["Item Code", ""],
    ["Item Description", ""],
    ["Heat Number", ""],
    ["Actions", "table-certificates-actions-header"]
  ];
  const columnKeys = [
      //Object key, Class
    ["__checkbox", ""],
    ["companycode", ""],
    ["bpcode", ""],
    ["salesordernumber", ""],
    ["salesorderlinenumber", ""],
    ["deliverynumber", ""],
    ["deliverylinenumber", ""],
    ["deliverydate", ""],
    ["customerreference", ""],
    ["salesorderlinereference", ""],
    ["itemcode", ""],
    ["itemdescription", ""],
    ["heatnumber", ""],
    ["__actions", "table-certificates-actions-data"]
  ];
  const [refreshTableCounter, setRefreshTableCounter] = useState(0);
  const [resetTableCounter, setResetTableCounter] = useState(0);
  const [renderTableCounter, setRenderTableCounter] = useState(0);
  const [tableDataCheckbox, setTableDataCheckbox] = useState([]);
  const [deliveryLineID, setDeliveryLineID] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [missFirst, setMissFirst] = useState(false);
  const tableActionDeleteIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faTrashAlt} color="red" title="Delete Certificate"></FontAwesomeIcon>;
  const tableActionEditIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faEdit} color="green" title="Edit Certificate"></FontAwesomeIcon>;
  const tableActionPdfIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faFilePdf} color="red" title="Open PDF"></FontAwesomeIcon>;
  const tableActionPdfIconUnavailable = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faFilePdf} color="gray" title="Certificate not required for material"></FontAwesomeIcon>;
  const searchChevronDown = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faChevronDown} className="mx-2" color="white" title="Show search parameters"></FontAwesomeIcon>;
  const searchChevronUp = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faChevronUp} className="mx-2" color="white" title="Hide search parameters"></FontAwesomeIcon>;
  const plusIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faPlus} color="white" title="Add"></FontAwesomeIcon>;
  const searchIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faSearch} color="white" title="Search"></FontAwesomeIcon>;
  const tableActionDelete = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    setModalObject(modalObject);
    setModalDeleteIsOpen(true);
  };
  const tableActionEdit = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    setModalObject(modalObject);
    setModalEditIsOpen(true);
  };
  const tableActionPdf = async function(dataItem) {
    let obj = {
      "id" : dataItem.deliverylineid
    };
    const response = await postApiRequest(JSON.stringify(obj), "certificate/download");
    if (response.status === 200) {
      const responseData = await response.json();
      const bString = window.atob(responseData.data);
      const bLength = bString.length;
      const bytes = new Uint8Array(bLength);
      for (let i = 0; i < bLength; i++) {
        bytes[i] = bString.charCodeAt(i);
      }
      const newBlob = new Blob([bytes], {type: "application/pdf"});
      const fileURL = URL.createObjectURL(newBlob);
        //NOTE(muamer): The following approach can be used to enforce our file name but it will be downloaded straight up
      //const link = document.createElement('a');
      //link.href = fileURL;
      //link.setAttribute('download', dataItem.deliverynumber + "_" + dataItem.deliverylinenumber);
      //document.body.appendChild(link);
      //link.click();
      window.open(fileURL);
    }
  };
  const massDownloadPdf = async function() {
    let obj = {
      "id" : []
    };
    for (let i = 0, n = deliveryLineID.length; i < n; i++) {
      if (tableDataCheckbox[i].checked === true) obj.id.push(deliveryLineID[i]);
    }
    const response = await postApiRequest(JSON.stringify(obj), "certificate/mass-download");
    if (response.status === 200) {
      const responseData = await response.json();
      const bString = window.atob(responseData.data);
      const bLength = bString.length;
      const bytes = new Uint8Array(bLength);
      for (let i = 0; i < bLength; i++) {
        bytes[i] = bString.charCodeAt(i);
      }
      const fileName = obj.id[0] + ".zip";
      const newBlob = new Blob([bytes], {type: "application/octet-stream"});
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(newBlob);
      elm.setAttribute('download', fileName);
      elm.click();
    }
  };
  const tableHeaderCheckboxClicked = function(e) {
    setTableHeaderCheck(function(isOn) {
      const valueToSet = isOn ? false : true;
      setTableDataCheckbox(function(arr) {
        let selectedAmount = 0;
        for (let i = 0, n = arr.length; i < n; i++) {
          if (arr[i].disabled === false) {
            arr[i].checked = valueToSet;
            selectedAmount++;
          }
        }
        if (valueToSet) setSelectedAmount(selectedAmount);
        else setSelectedAmount(0);
        return [...arr];
      });
      return valueToSet;
    });
  };
  const tableDataCheckboxClicked = function(index) {
    setTableDataCheckbox(function(arr) {
      arr[index].checked = !arr[index].checked;
      let isAllOn = true;
      let selectedAmount = 0;
      for (let i = 0, n = arr.length; i < n; i++) {
        if (arr[i].disabled === false) {
          if (arr[i].checked === false) isAllOn = false;
          else selectedAmount++;
        }
      }
      setTableHeaderCheck(isAllOn);
      setSelectedAmount(selectedAmount);
      return [...arr];
    });
  };
  const openModalAddAction = function() {
    let modalObject = {};
    setModalAddFormErrors({});
    setModalObject(modalObject);
    toggleModalAddIsOpen();
  };
  const toggleSearchIsOpen = () => setSearchIsOpen(function(prevSearchIsOpen) {
    let searchIsOpen = !prevSearchIsOpen;
    if (searchIsOpen == true) {
      setSearchButton(searchHideParamsButton);
    } else {
      setSearchButton(searchShowParamsButton);
    }
    return searchIsOpen;
  });
  const searchShowParamsButton = <Button type="button" color="primary" onClick={toggleSearchIsOpen} style={{width: "100%"}}>{searchChevronDown} Show search parameters {searchChevronDown}</Button>;
  const searchHideParamsButton = <Button type="button" className="mb-2" onClick={toggleSearchIsOpen} style={{width: "100%"}}>{searchChevronUp} Hide search parameters {searchChevronUp}</Button>;
  const [searchButton, setSearchButton] = useState(searchShowParamsButton);
  const searchCertificatesSubmit = function() {
    let params = "";
    if (salesOrder !== undefined && salesOrder.trim() !== "") params += "so_doc_num=" + encodeURIComponent(salesOrder) + "&";
    if (delivery !== undefined && delivery.trim() !== "") params += "del_doc_num=" + encodeURIComponent(delivery) + "&";
    if (customerReference !== undefined && customerReference.trim() !== "") params += "customer_reference=" + encodeURIComponent(customerReference) + "&";
    if (lineReference !== undefined && lineReference.trim() !== "") params += "so_line_reference=" + encodeURIComponent(lineReference) + "&";
    if (item !== undefined && item.trim() !== "") params += "item_code=" + encodeURIComponent(item) + "&";
    if (heatNumber !== undefined && heatNumber.trim() !== "") params += "heat_number=" + encodeURIComponent(heatNumber) + "&";
    if (customerCode !== undefined && customerCode != 0) params += "customer_code=" + encodeURIComponent(customerCode) + "&";
    if (company !== undefined && company != 0) params += "company_code=" + encodeURIComponent(company) + "&";
    params = params.substring(0, params.length - 1);
    setCertificateApiPath("certificates?"+params);
    setCertificateCountPath("certificates/count?"+params);
    setResetTableCounter((value) => (value+1));    
  };
  const toggleModalDeleteIsOpen = () => setModalDeleteIsOpen(!modalDeleteIsOpen);
  const toggleModalAddIsOpen = () => setModalAddIsOpen(!modalAddIsOpen);
  const toggleModalEditIsOpen = () => setModalEditIsOpen(!modalEditIsOpen);
  const modalDeleteSubmit = async function() {
    setModalDeleteSubmitButtonDisabled(true);
    const requestObject = {
      deliverylineid : modalObject.deliverylineid,
      salesorderlineid : modalObject.salesorderlineid,
    };
    const response = await postApiRequest(JSON.stringify(requestObject), "certificate/delete");
    if (response.status === 200) {
      setRefreshTableCounter((value) => value+1);
      setModalDeleteSubmitButtonDisabled(false);
      toggleModalDeleteIsOpen();
    }
  };
  const modalEditSubmit = async function() {
    setModalEditSubmitButtonDisabled(true);
    const patchObject = {
      deldocid: modalObject.deldocid,
      salesorderid: modalObject.salesorderid,
      deliverylineid: modalObject.deliverylineid,
      salesorderlineid: modalObject.salesorderlineid,
      customer_reference: modalObject.customerreference,
      line_reference: modalObject.salesorderlinereference,
      item_code: modalObject.itemcode,
      item_description: modalObject.itemdescription,
      heat_number: modalObject.heatnumber,
      certificate_file: modalObject.certificate_file,
      delivery_number: modalObject.deliveryumber,
      sales_order_number: modalObject.salesordernumber
    };
    const response = await postApiRequest(JSON.stringify(patchObject), "certificate/edit");
    if (response.status === 200) {
      setRefreshTableCounter((value) => value+1);
      setModalEditSubmitButtonDisabled(false);
    }
  };
  const modalAddSubmit = async function() {
    let requestObject = Object.assign({}, modalObject);
    setModalAddSubmitButtonDisabled(true);
    requestObject.del_line_num = Number(requestObject.del_line_num);
    requestObject.so_line_num = Number(requestObject.so_line_num);
    requestObject.customer_code = Number(requestObject.customer_code);
    const response = await postApiRequest(JSON.stringify(requestObject), "certificate");
    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.status === 1) {
        setRefreshTableCounter((value) => value+1);
        setModalAddSubmitButtonDisabled(false);
      } else {
        setModalAddFormErrors(function(prevObject) {
          return Object.assign({customer_code: "nigger"}, prevObject, responseData.errors);
        });
      }
    }
  };

  const dataReceivedCallback = function(data) {
    setSelectedAmount(0);
    let pdfButton = null;
    const n = data.length;
    const deliveryLineID = Array(n);
    const checkboxRef = Array(n);
    for (let i = 0; i < n; i++) {
      checkboxRef[i] = {
        checked: false,
        disabled: !data[i].hascertificate
      };
    }
    setTableHeaderCheck(false);
    setTableDataCheckbox([...checkboxRef]);
    for (let i = 0, n = data.length; i < n; i++) {
      deliveryLineID[i] = data[i].deliverylineid;
      if (data[i].deliverylinenumber == null) data[i].deliverylinenumber = 0;
      if (data[i].salesorderlinenumber == null) data[i].salesorderlinenumber = 0;
      data[i].__id = i;
      if (appState.role === 'admin' || appState.role === 'vluser') {
        data[i].__actions = [
          <Row>
            <Col xs="4">
              {data[i].hascertificate ? <span onClick={() => tableActionPdf(data[i])}>{tableActionPdfIcon}</span> : <span>{tableActionPdfIconUnavailable}</span>}
            </Col>
            <Col xs="4">
              <span onClick={() => tableActionEdit(data[i])}>{tableActionEditIcon}</span>
            </Col>
            <Col xs="4">
              <span onClick={() => tableActionDelete(data[i])}>{tableActionDeleteIcon}</span>
            </Col>
          </Row>
        ]
      } else {
        data[i].__actions = [
          <Row>
            <Col xs="4">
              {data[i].hascertificate ? <span onClick={() => tableActionPdf(data[i])}>{tableActionPdfIcon}</span> : <span>{tableActionPdfIconUnavailable}</span>}
            </Col>
          </Row>
        ];
      }
    }
    setDeliveryLineID([...deliveryLineID]);
    return data;
  }

  const uploadFile = function(evt, id) {
    let file = document.getElementById(id).files[0];
    console.log(file);
    let fr = new FileReader();
    fr.addEventListener('load', () => {
      setModalObject((prevMO) => Object.assign({}, prevMO, {certificate_file: btoa(fr.result)}));
    }, false);
    fr.readAsBinaryString(file);
  };

  const getValuesForAutocomplete = function(key, value) {
    return new Promise(async function(resolve) {
      if (value !== undefined && value.length > 0) {
        let response = await getApiRequest("certificates/autocomplete?attribute="+key+"&value="+encodeURIComponent(value));
        let responseData = await response.json();
        resolve(responseData);
      } else {
        resolve([]);
      }
    });
  };

  const autocompleteSetsValue = function(funPointer, value) {
    if (value == null) funPointer("");
    else funPointer(value.value);
  };
  
  useEffect(() => {
    document.title = "VLBC - Certificates";
  }, []);

  useEffect(() => {
    if (missFirst == true) searchCertificatesSubmit();
    else setMissFirst(true);
  }, [salesOrder, delivery, customerReference, lineReference, item, heatNumber, customerCode, company]);

  return (
  <Fragment>
    <div className="title-box">
      <div className="row">
        <div className="col-7">
          <h1 className="page-title">Certificates</h1>
        </div>
        <div className="col-5">
          <div className="d-flex flex-row-reverse">
            {(appState.role === 'admin' || appState.role === 'vluser') && <Button type="button" style={{position: "relative", top: '-0.45rem'}} color="primary" onClick={() => openModalAddAction()}>{plusIcon} Add certificate</Button>}
          </div>
        </div>
      </div>
    </div>
    <Container fluid className="element-box">
      <Row>
        <Col md="12">
          {searchButton}
        </Col>
      </Row>
      <Collapse isOpen={searchIsOpen}>
        <Form>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label>Sales Order</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setSalesOrder, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('so_doc_num', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Delivery</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setDelivery, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('del_doc_num', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Customer Reference</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setCustomerReference, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('customer_reference', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Line Reference</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setLineReference, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('so_line_reference', value)}}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label>Item Code</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setItem, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('item_code', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Heat Number</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setHeatNumber, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('heat_number', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Customer Code</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setCustomerCode, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('customer_code', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Company</Label>
                <AsyncSelect onChange={value => autocompleteSetsValue(setCompany, value)} backspaceRemovesValue={true} isClearable={true}
                  loadOptions={function(value){return getValuesForAutocomplete('company_code', value)}}/>
              </FormGroup>
            </Col>
            <Col md="3"></Col>
            <Col md="3"></Col>
            <Col md="3"></Col>
            <Col md="3">
              <FormGroup>
                <Button style={{width: "100%"}} type="button" color="primary" onClick={() => searchCertificatesSubmit()}>Search {searchIcon}</Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Collapse> 
    </Container>
    <div className="element-box">
      <CustomTable renderCounter={renderTableCounter} resetCounter={resetTableCounter} maxItemsLink={certificateCountPath} refreshCounter={refreshTableCounter} columns={tableColumns} columnKeys={columnKeys}
        apiPath={certificateApiPath} dataReceivedCallback={dataReceivedCallback} checkValues={tableDataCheckbox} checkboxCallback={tableDataCheckboxClicked} />
      <Button color="primary" style={{marginTop: "1rem"}} onClick={() => massDownloadPdf()} disabled={selectedAmount === 0}>Download selected ({selectedAmount})</Button>
    </div>
    {(appState.role === 'admin' || appState.role === 'vluser') && <Modal isOpen={modalEditIsOpen} className="modal-dialog modal-xl" toggle={toggleModalEditIsOpen}>
      <ModalHeader>Editing delivery : {modalObject.deliverynumber} - {modalObject.deliverylinenumber}</ModalHeader>
      <ModalBody>
        <Row className="mb-2">
          <Col md="6">
            <Label>Customer Code:</Label>
            <Input type="text" disabled="true" value={modalObject.bpcode}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-customer-reference">Customer Reference:</Label>
            <Input className={'form-control ' + (modalAddFormErrors.customer_reference != null ? "is-invalid" : "")} type="text" placeholder="Customer Reference" name="edit-form-customer-reference"
              value={modalObject.customerreference} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {customerreference: e.target.value}))}/>
            {modalAddFormErrors.customer_reference !== "" && <div className="invalid-feedback">{modalAddFormErrors.customer_reference}</div>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6">
            <Label>Sales Order:</Label>
            <Input type="text" disabled="true" value={modalObject.salesordernumber}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-line-reference">Line Reference:</Label>
            <Input className={'form-control ' + (modalAddFormErrors.line_reference != null ? "is-invalid" : "")} type="text" placeholder="Line Reference" name="edit-form-line-reference"
              value={modalObject.salesorderlinereference} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {salesorderlinereference: e.target.value}))}/>
            {modalAddFormErrors.line_reference !== "" && <div className="invalid-feedback">{modalAddFormErrors.line_reference}</div>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6">
            <Label>SO Line:</Label>
            <Input type="text" disabled="true" value={modalObject.salesorderlinenumber}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-item-code">Item Code:</Label>
            <Input className={'form-control ' + (modalAddFormErrors.item_code != null ? "is-invalid" : "")} type="text" placeholder="Item Code" name="edit-form-item-code"
              value={modalObject.itemcode} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {itemcode: e.target.value}))}/>
            {modalAddFormErrors.item_code !== "" && <div className="invalid-feedback">{modalAddFormErrors.item_code}</div>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6">
            <Label>Delivery:</Label>
            <Input type="text" disabled="true" value={modalObject.deliverynumber}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-item-description">Item Description:</Label>
            <Input className={'form-control ' + (modalAddFormErrors.item_description != null ? "is-invalid" : "")} type="text" placeholder="Item Description" name="edit-form-item-description"
              value={modalObject.itemdescription} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {itemdescription: e.target.value}))}/>
            {modalAddFormErrors.item_description !== "" && <div className="invalid-feedback">{modalAddFormErrors.item_description}</div>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6">
            <Label>Del Line:</Label>
            <Input type="text" disabled="true" value={modalObject.deliverylinenumber}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-heat-number">Heat Number:</Label>
            <Input className={'form-control ' + (modalAddFormErrors.heat_number != null ? "is-invalid" : "")} type="text" placeholder="Heat Number" name="edit-form-heat-number"
              value={modalObject.heatnumber} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {heatnumber: e.target.value}))}/>
            {modalAddFormErrors.heat_number !== "" && <div className="invalid-feedback">{modalAddFormErrors.heat_number}</div>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md="6">
            <Label>Del Doc Date:</Label>
            <Input type="text" disabled="true" value={modalObject.deliverydate}/>
          </Col>
          <Col md="6">
            <Label className="form-control-label" for="edit-form-certificate-file">Upload Certificate:</Label>
            <Input type="file" id="edit-certificate-file" className={'form-control ' + (modalAddFormErrors.certificate_file != null ? "is-invalid" : "")} name="edit-form-certificate-file" 
              onChange={(evt) => uploadFile(evt, "edit-certificate-file")} accept="application/pdf"/>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={modalEditSubmitButtonDisabled} onClick={() => modalEditSubmit()}>Update</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>}
    {(appState.role === 'admin' || appState.role === 'vluser') && <Modal isOpen={modalDeleteIsOpen} className="modal-lg" toggle={toggleModalDeleteIsOpen}>
      <ModalHeader>Delete Certificate : {modalObject.deliverynumber}</ModalHeader>
      <ModalBody>
        <h3>Do you really want to delete this certificate ?</h3>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={modalDeleteSubmitButtonDisabled} onClick={() => modalDeleteSubmit()}>Delete</Button>
        <Button color="secondary" onClick={() => toggleModalDeleteIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>}
    {(appState.role === 'admin' || appState.role === 'vluser') && <Modal isOpen={modalAddIsOpen} className="modal-dialog modal-xl" toggle={toggleModalAddIsOpen}>
      <ModalHeader>Add New Certificate</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <Row className="mb-2 mx-1">
              <Label style={{display: "block"}} className="form-control-label w-100" for="add-form-customer-code">Customer Code:</Label>
              <AsyncSelect className="w-100" name="add-form-customer-code" backspaceRemovesValue={true} isClearable={true} loadOptions={function(value){return getValuesForAutocomplete('customer_code', value)}}
                onChange={e => e != null && setModalObject((prevMO) => Object.assign({}, prevMO, {customer_code: e.value}))} />
              {modalAddFormErrors.customer_code !== "" && <div className="invalid-feedback" style={{display: "block"}}>{modalAddFormErrors.customer_code}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-sales-order">Sales Order:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.so_doc_num != null ? "is-invalid" : "")} type="number" placeholder="Sales Order" name="add-form-sales-order"
                value={modalObject.so_doc_num} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {so_doc_num: e.target.value}))}/>
              {modalAddFormErrors.so_doc_num !== "" && <div className="invalid-feedback">{modalAddFormErrors.so_doc_num}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-SO-line">SO Line:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.so_line_num != null ? "is-invalid" : "")} type="number" placeholder="SO Line" name="add-form-SO-line"
                value={modalObject.so_line_num} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {so_line_num: e.target.value}))}/>
              {modalAddFormErrors.so_line_num !== "" && <div className="invalid-feedback">{modalAddFormErrors.so_line_num}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-delivery">Delivery:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.del_doc_num != null ? "is-invalid" : "")} type="number" placeholder="Delivery" name="add-form-delivery"
                value={modalObject.del_doc_num} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {del_doc_num: e.target.value}))}/>
              {modalAddFormErrors.del_doc_num !== "" && <div className="invalid-feedback">{modalAddFormErrors.del_doc_num}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-del-line">Del Line:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.del_line_num != null ? "is-invalid" : "")} type="number" placeholder="Del Line" name="add-form-del-line"
                value={modalObject.del_line_num} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {del_line_num: e.target.value}))}/>
              {modalAddFormErrors.del_line_num !== "" && <div className="invalid-feedback">{modalAddFormErrors.del_line_num}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label>Del Doc Date:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.del_doc_date != null ? "is-invalid" : "")} type="text" placeholder="Del Doc Date (DD-MM-YY)"
                value={modalObject.del_doc_date} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {del_doc_date: e.target.value}))}/>
              {modalAddFormErrors.del_doc_date !== "" && <div className="invalid-feedback">{modalAddFormErrors.del_doc_date}</div>}
            </Row>
          </Col>
          <Col md="6">
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-customer-ref">Customer Reference:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.customer_reference != null ? "is-invalid" : "")} type="text" placeholder="Customer Reference" name="add-form-customer-reference" 
                value={modalObject.customer_reference} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {customer_reference: e.target.value}))}/>
              {modalAddFormErrors.customer_reference !== "" && <div className="invalid-feedback">{modalAddFormErrors.customer_reference}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-line-reference">Line Reference:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.line_reference != null ? "is-invalid" : "")} type="text" placeholder="Line Reference" name="add-form-line-reference" 
                value={modalObject.line_reference} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {line_reference: e.target.value}))}/>
              {modalAddFormErrors.line_reference !== "" && <div className="invalid-feedback">{modalAddFormErrors.line_reference}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-item-code">Item Code:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.item_code != null ? "is-invalid" : "")} type="text" placeholder="Item Code" name="add-form-item-code"
                value={modalObject.item_code} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {item_code: e.target.value}))}/>
              {modalAddFormErrors.item_code !== "" && <div className="invalid-feedback">{modalAddFormErrors.item_code}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-item-desc">Item Description:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.item_description != null ? "is-invalid" : "")} type="text" placeholder="Item Description" name="add-form-item-desc"
                value={modalObject.item_description} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {item_description: e.target.value}))}/>
              {modalAddFormErrors.item_description !== "" && <div className="invalid-feedback">{modalAddFormErrors.item_description}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-heat-num">Heat Number:</Label>
              <Input className={'form-control ' + (modalAddFormErrors.heat_number != null ? "is-invalid" : "")} type="text" placeholder="Heat Number" name="add-form-heat-num"
                value={modalObject.heat_number} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {heat_number: e.target.value}))}/>
              {modalAddFormErrors.heat_number !== "" && <div className="invalid-feedback">{modalAddFormErrors.heat_number}</div>}
            </Row>
            <Row className="mb-2 mx-1">
              <Label className="form-control-label" for="add-form-certificate-file">Upload Certificate:</Label>
              <Input type="file" id="certificate-file" className={'form-control ' + (modalAddFormErrors.certificate_file != null ? "is-invalid" : "")} name="add-form-certificate-file" 
                onChange={(evt) => uploadFile(evt, "certificate-file")} accept="application/pdf"/>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={modalAddSubmitButtonDisabled} onClick={() => modalAddSubmit()}>Add</Button>
        <Button color="secondary" onClick={() => toggleModalAddIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>}
  </Fragment>
  );
};

export default PageCertificates;