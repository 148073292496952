import React, {useState, useRef, useContext, useEffect} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col, Spinner, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Redirect, useHistory} from "react-router-dom";

import logo from '../assets/logo.png';
import logoOn from '../assets/logo-on.png';
import AppContext from '../AppContext.js';
import PageURL from "../PageURL.js";
import {postApiRequest, getCookieByName, apiURL} from "../Utils/Utils.js";

const PageLogin = function() {
	const loginPageFormConfirmationRef = useRef('');
	const [username, setUsername] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [modalForgotPasswordIsOpen, setModalForgotPasswordIsOpen] = useState(false);
	const [modalForgotPasswordSubmitDisabled, setModalForgotPasswordSubmitDisabled] = useState(false);
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
	const [forgotPasswordEmailError, setForgotPasswordEmailError] = useState("");
	const [loginPageFormConfirmation, setLoginPageFormConfirmation] = useState("");
	const {appState, setAppState} = useContext(AppContext);
	const history = useHistory();

	const toggleModalForgotPasswordIsOpen = () => setModalForgotPasswordIsOpen(!modalForgotPasswordIsOpen);

	const loginOnKeyDown = function(evt) {
    if (evt.keyCode == 13) handleSubmit(evt);
  };

  const forgotPasswordOnKeyDown = function(evt) {
    if (evt.keyCode == 13) modalForgotPasswordSubmit();
  };

	const modalForgotPasswordSubmit = async function() {
		const successMessage = "Success! Check your email for password recovery.";
		const forgotPasswordData = {
			'email' : forgotPasswordEmail
		};
		const response = await postApiRequest(JSON.stringify(forgotPasswordData), "user/reset-password/request");
		const responseData = await response.json();

		if (response.status === 200) {
			if (responseData.message === "OK") {
				setLoginPageFormConfirmation(successMessage);
				toggleModalForgotPasswordIsOpen();
				setTimeout(() => {
					if (loginPageFormConfirmationRef.current == successMessage) {
						setLoginPageFormConfirmation("");
					}
				}, 5000);
			} else {
				setForgotPasswordEmailError(responseData.message);
			}
		} else {
			setForgotPasswordEmailError("An unexpected error occured. Please try again later.");
		}
	};

	const handleSubmit = async function(evt) {
    evt.preventDefault();
    	//Do logic checks on fields like so
    let error = false;
    if (username.length < 1) {
    	setUsernameError("Username cannot be blank.");
    	error = true;
    } else setUsernameError("");
    if (password.length < 1) {
    	setPasswordError("Password cannot be blank.");
    	error = true;
    } else setPasswordError("");

    	//If error is false, we send the request for login
    if (error == false) {
    	const loginData = {
    		'email' : username,
    		'password' : password
    	};//'{"email": "admin@vlbcert.ba","password": "Strasnasifra1!"}';
    	const response = await postApiRequest(JSON.stringify(loginData), "user/login");
    	if (response.status === 200) {
    		const responseData = await response.json();
    		const cookieExpire = new Date(responseData.auth_key_expires_at).toGMTString();
    		document.cookie = "auth_key="+responseData.auth_key+"; expires="+cookieExpire+"; SameSite=Strict; Secure; Path=/";
    		const localStorage = window.localStorage;
    		localStorage.setItem('user', responseData.first_name + responseData.last_name);
    		localStorage.setItem('email', responseData.email);
    		localStorage.setItem('role', responseData.item_name);
    		setAppState({
    			user : responseData.first_name + responseData.last_name,
    			email : responseData.email,
    			role :  responseData.item_name
    		});
    	} else {
    		setPasswordError("Invalid credentials.");
    	}
    }
  };

  useEffect(() => {
    document.title = "VLBC - Certificates";
    if (appState['loginMessage'] !== undefined) {
    	const loginMessage = appState['loginMessage'];
    	setLoginPageFormConfirmation(loginMessage);
    	setTimeout(() => {
				if (loginPageFormConfirmationRef.current == loginMessage) {
					setLoginPageFormConfirmation("");
				}
			}, 5000);
    }
  }, []);

  useEffect(() => {
    loginPageFormConfirmationRef.current = loginPageFormConfirmation;
  }, [loginPageFormConfirmation]);

	return (
	<div className="login-page">
		<Row>
			<Col xl="3" className="login-content">
      	<div className="content d-flex align-items-center">
      	  <Row>
      	    <Col xs="12">
      	      <div className="logo-content mb-3"><img alt="logo" src={logo} /></div>
      	    </Col>
      	    <Col xs="12">
      	      <div className="text-content">
      	        Van Leeuwen is a globally operating trading company specialized in steel pipes, and pipe and tube applications. We are active in virtually all industrial sectors. With some forty branches spread throughout Europe, the Middle East, Asia, Australia and America, we ensure that materials are available anywhere in the world, customized wherever needed. Our employees have specialist knowledge of sourcing, project management, logistics and stock planning and work closely together with our customers in the Industry and Energy markets. The combination of global logistics and knowledge of products and customer applications makes Van Leeuwen a leading company in its markets.
      	      </div>
      	    </Col>
      	  </Row>
      	</div>
     	</Col>
     	<Col xl="9" className="login-form">
     		<div className="form d-flex flex-column justify-content-center align-items-center">
     			<Row className="mb-3">
     				<Col xs="12">
              <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
            </Col>
            <Col xs="12">
            	<Form onSubmit={handleSubmit} style={{maxWidth: '10000px'}}>
								<FormGroup>
									<Label className="form-control-label" for="username">Username</Label>
									<Input className={'form-control ' + (usernameError !== "" ? "is-invalid" : "")} id="username" autoComplete="username" placeholder="Enter Your Username" value={username}
										onChange={e => setUsername(e.target.value)} onKeyDown={loginOnKeyDown} />
									{usernameError !== "" && <div className="invalid-feedback">{usernameError}</div>}
								</FormGroup>
								<FormGroup>
									<Label className="form-control-label" for="password">Password</Label>
									<Input className={'form-control ' + (passwordError !== "" ? "is-invalid" : "")} id="password" autoComplete="current-password" type="password" placeholder="Enter Your Password"
										onChange={e => setPassword(e.target.value)} onKeyDown={loginOnKeyDown} />
									{passwordError !== "" && <div className="invalid-feedback">{passwordError}</div>}
								</FormGroup>
								<Row>
									<Col xs="6" style={{padding: 0}}>
										<Button type="submit" color="primary">Login</Button>
									</Col>
									<Col xs="6" style={{padding: 0, textAlign: 'right'}}>
										<span type="button" class="text-info" onClick={() => toggleModalForgotPasswordIsOpen()}>Forgot password?</span>
									</Col>
								</Row>
							</Form>
            </Col>
          </Row>
          <Row>
          	<Col xs="12">
          		<span type="button" onClick={() => setLoginPageFormConfirmation("")} className="text-success">{loginPageFormConfirmation}</span>
          	</Col>
          </Row>
        </div>
      </Col>
    </Row>
    <Modal isOpen={modalForgotPasswordIsOpen} className="modal-dialog modal-dialog-centered" toggle={toggleModalForgotPasswordIsOpen}>
        <ModalHeader>Forgot password</ModalHeader>
        <ModalBody>
          <Row>
          	<Row className="mb-2 ml-2 ">
          		<Col xs="12">
          			<Label className="form-control-label" for="forgot-password-email">Registered account's email</Label>
          		</Col>
          		<Col xs="12">
          			<Input className={'form-control ' + (forgotPasswordEmailError !== "" ? "is-invalid" : "")} id="forgot-password-email" placeholder="Please enter your email address" value={forgotPasswordEmail}
          				onChange={e => setForgotPasswordEmail(e.target.value)} onKeyDown={forgotPasswordOnKeyDown} />
          			{forgotPasswordEmailError !== "" && <div className="invalid-feedback">{forgotPasswordEmailError}</div>}
          		</Col>
          	</Row>
          </Row>
        </ModalBody>
        <ModalFooter>
        	<Button color="primary" disabled={modalForgotPasswordSubmitDisabled} onClick={() => modalForgotPasswordSubmit()}>Send Email</Button>
        	<Button color="secondary" onClick={() => toggleModalForgotPasswordIsOpen()}>Cancel</Button>
        </ModalFooter>
      </Modal>
	</div>
	);
};

export default PageLogin;