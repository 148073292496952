import React, {useState, useEffect, Fragment} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col, Container} from 'reactstrap';

import {patchApiRequest, postApiRequest, getApiRequest} from "../Utils/Utils.js";

const PageMyAccount = function() {
	const [newPassword, setNewPassword] = useState("");
	const [repeatNewPassword, setRepeatNewPassword] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
	const [myAccountData, setMyAccountData] = useState({});

	const handleSubmit = async function(evt) {
		setSubmitButtonDisabled(true);
    evt.preventDefault();
    let errorObject = {};
    if (newPassword === undefined || newPassword.length < 14) {
      errorObject.password = "Password must have more than 13 characters.";
    } else if (!checkPasswordRules(newPassword)) {
    	errorObject.password = "Password must contain an upper case and lower case letter, a digit and a special character.";
    } else if (newPassword !== repeatNewPassword) {
      errorObject.password = "Needs to match Repeat Password";
      errorObject.repeat_password = "Needs to match Password";
    }
    if (Object.keys(errorObject).length === 0) {
    	const response = await patchApiRequest(JSON.stringify({password: newPassword}), "user/current/change-password");
    	const responseData = await response.json();
    	if (response.status >= 200 && response.status < 205) {
    		getUserInfo();
    	} else {

    	}
    	setSubmitButtonDisabled(false);
    } else {
    	setSubmitButtonDisabled(false);
    	setFormErrors(errorObject);
    }
  };

  const checkPasswordRules = function(pass) {
  	const re = /^(?=.*\d)(?=.*[!@#_+\\\-'$%^&*])(?=.*[a-z])(?=.*[A-Z]).{14,}$/;
  	return re.test(pass);
  };

  const getUserInfo = async function() {
  	const response = getApiRequest("user/information")
    .then(function(response) {
    	if (response.status === 200) {
    		response.json().then(function(responseData){
    			setFormErrors({});
    			setMyAccountData(Object.assign({}, responseData));
    		});
    	}
    });
  };

  useEffect(() => {
    document.title = "VLBC - My Account";
    getUserInfo();
  }, []);

  return (
  	<Fragment>
  	<Row>
  		<Col md="6">
  			<Container fluid className="element-box">
  				<div className="title-box">
    			  <h1 className="page-title">Your account details</h1>
    			</div>
  				<Row>
  					<Col xs="4">
  						<Label>Email:</Label>
  					</Col>
  					<Col xs="8">
  						<span>{myAccountData.email}</span>
  					</Col>
  				</Row>
  				<Row>
  					<Col xs="4">
  						<Label>First name:</Label>
  					</Col>
  					<Col xs="8">
  						<span>{myAccountData.first_name}</span>
  					</Col>
  				</Row>
  				<Row>
  					<Col xs="4">
  						<Label>Last name:</Label>
  					</Col>
  					<Col xs="8">
  						<span>{myAccountData.last_name}</span>
  					</Col>
  				</Row>
  				<Row>
  					<Col xs="12">
  						<span>{"Resetting your password by "+ (new Date(myAccountData.password_reset_timestamp*1000).toLocaleString())+" is mandatory."}</span>
  					</Col>
  				</Row>
  			</Container>
  		</Col>
  		<Col md="6">
  			<Container fluid className="element-box">
  				<div className="title-box">
    			  <h1 className="page-title">Change Your Password</h1>
    			</div>
  				<Form onSubmit={handleSubmit}>
						<FormGroup>
							<Label className="form-control-label" for="new-password">Your New Password</Label>
							<Input className={'form-control ' + (formErrors.password != null ? "is-invalid" : "")} name="new-password" type="password"
								placeholder="Enter Your New Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
							{formErrors.password !== "" && <div className="invalid-feedback">{formErrors.password}</div>}
						</FormGroup>
						<FormGroup>
							<Label className="form-control-label" for="repeat-password">Repeat Your New Password</Label>
							<Input className={'form-control ' + (formErrors.repeat_password != null ? "is-invalid" : "")} name="repeat-password" type="password"
								placeholder="Repeat Your New Password" value={repeatNewPassword} onChange={e => setRepeatNewPassword(e.target.value)} />
							{formErrors.repeat_password !== "" && <div className="invalid-feedback">{formErrors.repeat_password }</div>}
						</FormGroup>
						<Button type="submit" color="primary" disabled={submitButtonDisabled}>Submit</Button>
					</Form>
  			</Container>
  		</Col>
  	</Row>
  	</Fragment>
  );
};

export default PageMyAccount;