import React, {useState, Fragment, useEffect} from 'react';
import {Form, Row, Col, Button, Label, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, Tab, TabContent, TabPane, Card, CardTitle, CardText, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faCheck} from '@fortawesome/free-solid-svg-icons'

import CustomTable from '../Utils/CustomTable.js';
import {getApiRequest, postApiRequest} from "../Utils/Utils.js";

const PageCustomerAccounts = function() {
  const [accountManagers, setAccountManagers] = useState([]);
	const [companyCode, setCompanyCode] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [repeatNewPassword, setRepeatNewPassword] = useState("");
	const [modalObject, setModalObject] = useState({});
	const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalEditSubmitButtonDisabled, setModalEditSubmitButtonDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [contactPersonPath, setContactPersonPath] = useState("");
  const [contactPersonTableRefresh, setContactPersonTableRefresh] = useState(0);
	const tableColumns = [
      //Text, Class
    ["Business Partner ID", ""],
    ["Company Code", ""],
    ["Company Name", ""],
    ["Country", ""],
    ["Account Manager", ""],
    ["Is Mother", ""],
    ["Actions", ""]
  ];
  const columnKeys = [
      //Object key, Class
    ["bp_id", ""],
    ["bp_company_code", ""],
    ["bp_company_name", ""],
    ["country_code", ""],
    ["account_manager_name", ""],
    ["__is_mother", ""],
    ["__actions", ""]
  ];
  const [customerTableRefresh, setCustomerTableRefresh] = useState(0);
  const [resetTableCounter, setResetTableCounter] = useState(0);
  const [customerTableApiPath, setCustomerTableApiPath] = useState("customers");
  const contactPersonColumns = [
      //Text, Class
    ["Name", ""],
    ["Last Name", ""],
    ["User Name", ""]
  ];
  const contactPersonColumnKeys = [
      //Object key, Class
    ["first_name", ""],
    ["last_name", ""],
    ["email", ""]
  ];
  const [allUsers, setAllUsers] = useState([]);
  const tableActionEditIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faEdit} color="green" title="Edit"></FontAwesomeIcon>;
  const tableCheckedIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faCheck} color="green" title="Yes"></FontAwesomeIcon>;
  const tableActionEdit = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    setModalObject(modalObject);
    setContactPersonPath("customer/contact-persons?id="+modalObject.bp_id);
    setModalEditIsOpen(true);
  };
  const modalCustomerSubmit = async function() {
    setModalEditSubmitButtonDisabled(true);
    const postObject = {
      bp_id: modalObject.bp_id,
      is_mother : modalObject.bp_is_mother,
      dop_access : modalObject.bp_dop_access,
      certificates_access : modalObject.bp_certificates_access,
      account_manager : modalObject.account_manager_id
    };
    const response = await postApiRequest(JSON.stringify(postObject), "customer/update-customer");
    if (response.status === 200) {
      setCustomerTableRefresh((value) => value+1);
      setModalEditSubmitButtonDisabled(false);
    }
  };
  const modalCertificateSubmit = function() {

  };
  const[check, setCheck] = useState(false);

  const toggleModalEditIsOpen = () => setModalEditIsOpen(!modalEditIsOpen);
  const toggleActiveTab = tab => {if(activeTab !== tab) setActiveTab(tab);}
  const toggleCheckBox = function(key) {
    setModalObject(function(previousModalObject) {
      let modalObject = Object.assign({}, previousModalObject);
      modalObject[key] = !modalObject[key];
      return modalObject;
    });
  };

  const dataReceivedCallback = function(data) {
  	for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__is_mother = data[i].bp_is_mother ? tableCheckedIcon : null;
      data[i].__actions = [
        <span onClick={() => tableActionEdit(data[i])}>{tableActionEditIcon}</span>
      ];
    }
  	return data;
  };
  const contactPersonCallback = function(data) {
    return data;
  };

	const searchSubmit = (evt) => {
    let apiPath = "customers?";
    if (companyCode != "") apiPath += "bp_code=" + companyCode + "&";
    if (companyName != "") apiPath += "bp_name=" + companyName + "&";
    apiPath = apiPath.substring(0, apiPath.length - 1);
    setCustomerTableApiPath(apiPath);
    setResetTableCounter((value) => (value+1));
  };

  const customerOnKeyDown = function(evt) {
    if (evt.keyCode == 13) searchSubmit(evt);
  };

  useEffect(async function() {
    document.title = "VLBC - Customer Accounts";
    let responseData = [];
    let response = await getApiRequest("account-managers");
    if (response.status === 200) {
      responseData = await response.json();
      setAccountManagers(responseData);
    }
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <h1 className="page-title">Customer Accounts</h1>
    </div>
    <Container fluid className="element-box">
  	  <Row>
  	  	<Col md="5" className="mb-2">
  	  		<Label>Company Code</Label>
  	  		<Input placeholder="Enter the Company Code" value={companyCode} onChange={e => setCompanyCode(e.target.value)} onKeyDown={customerOnKeyDown}/>
  	  	</Col>
  	  	<Col md="5" className="mb-2">
  	  		<Label>Company Name</Label>
  	  		<Input placeholder="Enter the Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} onKeyDown={customerOnKeyDown}/>
  	  	</Col>
  	  	<Col md="2" className="text-center align-self-end">
  	  		<Button style={{width: "100%", marginBottom: "8px"}} color="primary" onClick={(evt) => searchSubmit(evt)}>Search</Button>
  	  	</Col>
  	  </Row>
    </Container>
  	<Container fluid className="element-box">
      <CustomTable maxItemsLink="/customers/count" resetCounter={resetTableCounter} refreshCounter={customerTableRefresh} columns={tableColumns} columnKeys={columnKeys} 
        apiPath={customerTableApiPath} dataReceivedCallback={dataReceivedCallback}></CustomTable>
    </Container>
  	<Modal isOpen={modalEditIsOpen} className="modal-dialog modal-xl" toggle={toggleModalEditIsOpen}>
    <ModalHeader>{modalObject.bp_id + ": " + modalObject.bp_company_name}</ModalHeader>
    <ModalBody>
      <Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={{ active: activeTab === '1' }}
            style={{cursor: "pointer"}}
            onClick={() => {toggleActiveTab('1')}}
          >
            Customer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === '2' }}
            style={{cursor: "pointer"}}
            onClick={() => {toggleActiveTab('2')}}
          >
            Contact Persons
          </NavLink>
        </NavItem>
      </Nav>
      </Row>
      <TabContent activeTab={activeTab}>
        <div style={{height: "1rem"}}></div>
        <TabPane tabId="1">
          <div className="title-box">
            <h1 className="page-title">Details</h1>
          </div>
          <Container fluid className="element-box">
            <Row className="mb-2">
              <Col md="3">
                <Label>Company Code:</Label>
              </Col>
              <Col md="9">
                <Label>{modalObject.bp_company_code}</Label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3">
                <Label>Company:</Label>
              </Col>
              <Col md="9">
                <Label>{modalObject.bp_company_name}</Label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3">
                <Label>Country:</Label>
              </Col>
              <Col md="9">
                <Label>{modalObject.country_code}</Label>
              </Col>
            </Row>
            <div className="mb-2">
              <Row>
                <Col md="3" className="content d-flex align-items-center">
                  <Label for="edit-form-account-manager">Account Manager:</Label>
                </Col>
                <Col md="9">
                  <Input name="edit-form-account-manager" type="select" onChange={(e) => setModalObject((prevMO) => Object.assign({}, prevMO, {account_manager_id: e.target.value}))}>
                    {accountManagers.map(item => (item.id !== modalObject.account_manager_id ? 
                      <option value={item.id}>{item.name}</option> : 
                      <option selected="selected" value={item.id}>{item.name}</option>)
                    )}
                 </Input>
                </Col>
              </Row>
            </div>
            <FormGroup check>
              <Label check onClick={(e) => toggleCheckBox("bp_is_mother")}>
                <Input checked={modalObject.bp_is_mother} type="checkbox" onClick={(e) => toggleCheckBox("bp_is_mother")}/>{' '}
                Mother Company
              </Label>
            </FormGroup>
          </Container>
          <div className="title-box" style={{margin: "1"}}>
            <h1 className="page-title">Portal Pages</h1>
          </div>
          <Container fluid className="element-box">
            <FormGroup check>
              <Label check onClick={(e) => toggleCheckBox("bp_dop_access")}>
                <Input type="checkbox" checked={modalObject.bp_dop_access} onClick={(e) => toggleCheckBox("bp_dop_access")}/>{' '}
                DOP
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label onClick={(e) => toggleCheckBox("bp_certificates_access")}>
                <Input type="checkbox" checked={modalObject.bp_certificates_access} onClick={(e) => toggleCheckBox("bp_certificates_access")}/>{' '}
                Certificates
              </Label>
            </FormGroup>
          </Container>
        </TabPane>
        <TabPane tabId="2">
          <CustomTable columns={contactPersonColumns} columnKeys={contactPersonColumnKeys} apiPath={contactPersonPath} dataReceivedCallback={contactPersonCallback} 
            refreshCounter={contactPersonTableRefresh}></CustomTable>
        </TabPane>
      </TabContent>
    </ModalBody>
    <ModalFooter>
      {activeTab === "1" &&
      <Fragment>
        <Button disabled={modalEditSubmitButtonDisabled} color="primary" onClick={() => modalCustomerSubmit()}>Update Customer</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </Fragment>}
      {activeTab === "2" &&
      <Fragment>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </Fragment>}
    </ModalFooter>
    </Modal>
  </Fragment>
  );
};

export default PageCustomerAccounts;