import React, {useState, useContext, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";

import PageLogin from "./PageLogin.js";
import {postApiRequest, getCookieByName, apiURL} from "../Utils/Utils.js";
import AppContext from '../AppContext.js';
import PageURL from "../PageURL.js";

const CheckLogin = function() {
	const [renderComponent, setRenderComponent] = useState(false);
	const {appState, setAppState} = useContext(AppContext);

	useEffect(async function() {
    document.title = "VLBC - Certificates";
    const authKey = getCookieByName('auth_key');
    if (authKey !== "") {
      const localStorage = window.localStorage;
      const timestamp = Date.now();
      const email = localStorage.getItem('email');
      if (localStorage.getItem("lastCheckedCredentials") === null || (timestamp - (+localStorage.getItem("lastCheckedCredentials")) >= 900000)) {
        const postData = {
          email : email,
          auth_key : authKey
        };
        const response = await postApiRequest(JSON.stringify(postData), "user/check-login");
        if (response.status === 200) {
          const responseData = await response.json();
          if (responseData.status === 'ok') {
            localStorage.setItem("lastCheckedCredentials", Date.now());
            setAppState({
              user : localStorage.getItem('user'),
              email : email,
              role :  localStorage.getItem('role')
            });
          } else {
            setRenderComponent(true);
          }
        } else if (response.status === 401) {
          setRenderComponent(true);
        } else {
          setAppState({
            loginMessage: "Server is down at the moment. Please come back later."
          });
          setRenderComponent(true);
        }
      } else {
        setAppState({
          user : localStorage.getItem('user'),
          email : email,
          role :  localStorage.getItem('role')
        });
      }
    } else {
    	setRenderComponent(true);
    }
  }, []);

  if (renderComponent === false) {
  	return (
  		<div></div>
  	);
  } else {
  	return (
  		<PageLogin/>
  	);
  }
};

export default CheckLogin;