import React, {useState, useEffect, Fragment, useContext} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faPlus} from '@fortawesome/free-solid-svg-icons';

import AppContext from '../AppContext.js';
import CustomTable from '../Utils/CustomTable.js';
import {checkPasswordRules, getApiRequest, postApiRequest} from "../Utils/Utils.js";

const PageUserAccounts = function() {
  const {appState, setAppState} = useContext(AppContext);
	const [name, setName] = useState("");
	const [username, setUsername] = useState("");
  const [modalObject, setModalObject] = useState({});
  const [userApiPath, setUserApiPath] = useState("users");
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalAddFormErrors, setModalAddFormErrors] = useState({});
  const [modalAddSubmitButtonDisabled, setModalAddSubmitButtonDisabled] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalEditFormErrors, setModalEditFormErrors] = useState({});
  const [modalEditSubmitButtonDisabled, setModalEditSubmitButtonDisabled] = useState(false);
  const [userTableRefreshCounter, setUserTableRefreshCounter] = useState(0);
  const [resetTableCounter, setResetTableCounter] = useState(0);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
	const tableColumns = [
      //Text, Class
    ["Name", ""],
    ["Username", ""],
    ["Actions", ""],
    ["Role", ""]
  ];
  const columnKeys = [
      //Object key, Class
    ["__name", ""],
    ["email", ""],
    ["role", ""],
    ["__actions", "table-user-accounts-actions-data"]
  ];
  const tableActionEditIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faEdit} color="green" title="Edit"></FontAwesomeIcon>;
  const addNewUserIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faPlus} color="white" title="Add"></FontAwesomeIcon>;
  const tableActionEdit = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    modalObject.name = modalObject.first_name + " " + modalObject.last_name;
    for (let i = 0, n = allCompanies.length; i < n; i++) {
      if (allCompanies[i].company_code === modalObject.company_code) {
        modalObject.company = allCompanies[i].id;
      }
    }
    setModalObject(modalObject);
    setModalEditIsOpen(true);
  };
  const dataReceivedCallback = function(data) {
  	for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__name = data[i].first_name + " " + data[i].last_name;
      data[i].__actions = [
        <span onClick={() => tableActionEdit(data[i])}>{tableActionEditIcon}</span>
      ]
    }
  	return data;
  };

  const toggleModalAddIsOpen = () => setModalAddIsOpen(!modalAddIsOpen);
  const toggleModalEditIsOpen = () => setModalEditIsOpen(!modalEditIsOpen);
  const openModalAddAction = async function() {
    setModalObject({
      role: 'user'
    });
    setModalAddFormErrors({});
    setModalAddIsOpen(true);
  };

  const modalAddSubmit = async function() {
    setModalAddSubmitButtonDisabled(true);
    let errorObject = {};
    if (modalObject.password === undefined || modalObject.password.length < 14) {
      errorObject.password = "Password must have more than 13 characters.";
    } else if (!checkPasswordRules(modalObject.password)) {
      errorObject.password = "Password must contain an upper case and lower case letter, a digit and a special character.";
    }else if (modalObject.password !== modalObject.repeat_password) {
      errorObject.password = "Needs to match Repeat Password";
      errorObject.repeat_password = "Needs to match Password";
    }
    if (modalObject.email === undefined || modalObject.email.trim() === "") errorObject.email = "This field is mandatory.";
    if (Object.keys(errorObject).length === 0) {
      const response = await postApiRequest(JSON.stringify(modalObject), "user/create-user");
      const responseData = await response.json();
      if (response.status === 200) {
        if (responseData.status === 0) {
          errorObject = {};
          const keys = Object.keys(responseData.errors);
          keys.forEach((member) => {
            errorObject[member] = responseData.errors[member];
          });
          setModalAddFormErrors(errorObject);
        } else {
          setUserTableRefreshCounter((value) => (value+1));
        }
        setModalAddSubmitButtonDisabled(false);
        
      }
    } else {
      setModalAddFormErrors(errorObject);
      setModalAddSubmitButtonDisabled(false);
    }
  };
  const modalEditSubmit = async function() {
    setModalEditSubmitButtonDisabled(true);
    let patchObject = {};
    patchObject.company = modalObject.company;
    patchObject.first_name = modalObject.name.trim().split(/ (.+)/)[0];
    patchObject.last_name = modalObject.name.trim().split(/ (.+)/)[1];
    patchObject.user_id = modalObject.id;
    patchObject.role = modalObject.role;
    const response = await postApiRequest(JSON.stringify(patchObject), "user/update-user");
    const responseData = await response.json();
    if (response.status >= 200 && response.status < 205) {
      setModalEditSubmitButtonDisabled(false);
      setUserTableRefreshCounter((value) => (value+1));
    } else {
      setModalEditSubmitButtonDisabled(false);
    }
  };

  const searchSubmit = (evt) => {
    evt.preventDefault();
    let apiPath = "users?";
    if (name != "") apiPath += "name=" + name + "&";
    if (username != "") apiPath += "email=" + username + "&";
    apiPath = apiPath.substring(0, apiPath.length - 1);
    setUserApiPath(apiPath);
    setResetTableCounter((value) => (value+1));
  };

  const userOnKeyDown = function(evt) {
    if (evt.keyCode == 13) searchSubmit(evt);
  };

  useEffect(async function() {
    document.title = "VLBC - User Accounts";
    let responseData = [];
    let response = await getApiRequest("company");
    if (response.status === 200) {
      responseData = await response.json();
      setAllCompanies(responseData);
    }
    response = await getApiRequest("roles");
    if (response.status === 200) {
      responseData = await response.json();
      setAllRoles(responseData);
    }
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <div className="row">
        <div className="col-7">
          <h1 className="page-title">User Accounts</h1>
        </div>
        <div className="col-5">
          <div className="d-flex flex-row-reverse">
            <Button type="button" color="primary" style={{position: "relative", top: '-0.45rem'}} onClick={() => openModalAddAction()}>{addNewUserIcon} Add user</Button>
          </div>
        </div>
      </div>
    </div>
    <Container fluid className="element-box">
  	  <Row>
  	  	<Col md="5" className="mb-2">
  	  		<Label>Name</Label>
  	  		<Input placeholder="Enter the Name" value={name} onChange={e => setName(e.target.value)} onKeyDown={userOnKeyDown} />
  	  	</Col>
  	  	<Col md="5" className="mb-2">
  	  		<Label>Username</Label>
  	  		<Input placeholder="Enter the Username" value={username} onChange={e => setUsername(e.target.value)} onKeyDown={userOnKeyDown} />
  	  	</Col>
  	  	<Col md="2" className="text-center align-self-end">
  	  		<Button style={{width: "100%", marginBottom: "8px"}} color="primary" onClick={(evt) => searchSubmit(evt)}>Search</Button>
  	  	</Col>
  	  </Row>
    </Container>
  	<Container fluid className="element-box">
      <CustomTable resetCounter={resetTableCounter} refreshCounter={userTableRefreshCounter} columns={tableColumns} columnKeys={columnKeys} apiPath={userApiPath} 
        dataReceivedCallback={dataReceivedCallback}></CustomTable>
    </Container>
    <Modal isOpen={modalAddIsOpen} className="modal-dialog modal-xl" toggle={toggleModalAddIsOpen}>
      <ModalHeader>Add New User</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-first-name">First name</Label>
            <Input className={'form-control ' + (modalAddFormErrors.first_name != null ? "is-invalid" : "")} type="text" placeholder="First name" name="add-form-first-name" value={modalObject.first_name}
              onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {first_name: e.target.value}))}/>
            {modalAddFormErrors.first_name !== "" && <div className="invalid-feedback">{modalAddFormErrors.first_name}</div>}
          </Col>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-last-name">Last name</Label>
            <Input className={'form-control ' + (modalAddFormErrors.last_name != null ? "is-invalid" : "")} type="text" placeholder="Last name" name="add-form-last-name" value={modalObject.last_name}
              onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {last_name: e.target.value}))}/>
            {modalAddFormErrors.last_name !== "" && <div className="invalid-feedback">{modalAddFormErrors.last_name}</div>}
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-email">Email</Label>
            <Input className={'form-control ' + (modalAddFormErrors.email != null ? "is-invalid" : "")} type="text" placeholder="Email" name="add-form-email" value={modalObject.email}
              onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {email: e.target.value}))}/>
            {modalAddFormErrors.email !== "" && <div className="invalid-feedback">{modalAddFormErrors.email}</div>}
          </Col>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-company">Company</Label>
            <Input className={'form-control ' + (modalAddFormErrors.company != null ? "is-invalid" : "")} type="select" onChange={(e) => setModalObject((prevMO) => Object.assign({}, prevMO, {company: e.target.value}))}>
              <option value="" selected disabled hidden>Select user's company</option>
              {allCompanies.map(item => (
                <option value={item.id}>{item.company_name}</option>
              ))}
            </Input>
            {modalAddFormErrors.company_code !== "" && <div className="invalid-feedback">{modalAddFormErrors.company_code}</div>}
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-password">Password</Label>
            <Input className={'form-control ' + (modalAddFormErrors.password != null ? "is-invalid" : "")} type="password" placeholder="Password" name="add-form-password" value={modalObject.password}
              onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {password: e.target.value}))}/>
            {modalAddFormErrors.password !== "" && <div className="invalid-feedback">{modalAddFormErrors.password}</div>}
          </Col>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-repeat-password">Repeat the Password</Label>
            <Input className={'form-control ' + (modalAddFormErrors.repeat_password != null ? "is-invalid" : "")} type="password" placeholder="Password" name="add-form-repeat-password" value={modalObject.repeat_password}
              onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {repeat_password: e.target.value}))}/>
            {modalAddFormErrors.repeat_password !== "" && <div className="invalid-feedback">{modalAddFormErrors.repeat_password}</div>}
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-2">
            <Label className="form-control-label" for="add-form-role">Role</Label>
            <Input name="add-form-role" type="select" onChange={(e) => setModalObject((prevMO) => Object.assign({}, prevMO, {role: e.target.value}))}>
              {allRoles.map(item => (item.name !== 'user' ? 
                <option value={item.name}>{item.name}</option> : 
                <option selected="selected" value={item.name}>{item.name}</option>)
              )}
            </Input>
          </Col>
          <Col md="6">
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={modalAddSubmitButtonDisabled} onClick={() => modalAddSubmit()}>Add</Button>
        <Button color="secondary" onClick={() => toggleModalAddIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>
    <Modal isOpen={modalEditIsOpen} className="modal-dialog modal-xl" toggle={toggleModalEditIsOpen}>
      <ModalHeader>Editing User: {modalObject.email}</ModalHeader>
      <ModalBody>
        <div className="title-box">
          <h1 className="page-title">Certificate Details</h1>
        </div>
        <Container fluid className="element-box">
          <Row className="mb-2">
            <Col md="3">
              <Label>Username:</Label>
            </Col>
            <Col md="9">
              <Input value={modalObject.email} disabled="true"/>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3">
              <Label className="form-control-label" for="edit-form-name">Name:</Label>
            </Col>
            <Col md="9">
              <Input className={'form-control ' + (modalEditFormErrors.name != null ? "is-invalid" : "")} type="text" name="add-form-name"
                value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3">
              <Label className="form-control-label" for="edit-form-company">Company:</Label>
            </Col>
            <Col md="9">
              <Input name="edit-form-company" type="select" onChange={(e) => setModalObject((prevMO) => Object.assign({}, prevMO, {company: e.target.value}))}>
                {allCompanies.map(item => (item.company_code !== modalObject.company_code ? 
                  <option value={item.id}>{item.company_name}</option> : 
                  <option selected="selected" value={item.id}>{item.company_name}</option>)
                )}
                {modalObject.company_code == null && <option value="" selected disabled hidden>Select user's company</option>}
              </Input>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3">
              <Label className="form-control-label" for="edit-form-role">Role:</Label>
            </Col>
            <Col md="9">
              <Input name="edit-form-role" type="select" onChange={(e) => setModalObject((prevMO) => Object.assign({}, prevMO, {role: e.target.value}))}>
                {allRoles.map(item => (item.name !== modalObject.role ? 
                  <option value={item.name}>{item.name}</option> : 
                  <option selected="selected" value={item.name}>{item.name}</option>)
                )}
              </Input>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={modalEditSubmitButtonDisabled} onClick={() => modalEditSubmit()}>Update</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </Fragment>
  );
};

export default PageUserAccounts;