import React, {useState, useEffect, Fragment} from 'react';
import {Table, Spinner, Button, Input, FormGroup, Label} from 'reactstrap';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {getApiRequest} from "./Utils.js";

const CustomTable = function({columns, columnKeys, apiPath, dataReceivedCallback, addBeforePaginationInBar, addToPaginationInBar, refreshCounter=0, maxItemsLink=null, 
  checkboxCallback=null, resetCounter=0, checkValues=null}) {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(15);
	const [tableItems, setTableItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1,2,3,4,5]);
  const [tableRefreshCounter, setTableRefreshCounter] = useState(0);
  const [tableResetCounter, setTableResetCounter] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [isDataZero, setIsDataZero] = useState(true);

  const tableRefreshFunction = function() {
    updateElements();
  };

	const updateElements = async function() {
    setIsLoading(true);
    let completePath;
    if (apiPath.includes("?") === true) {
      completePath = apiPath + "&pagination="+(page+1)+","+pageSize;
    } else {
      completePath = apiPath + "?pagination="+(page+1)+","+pageSize;
    }
    const response = await getApiRequest(completePath);
    if (maxItemsLink === null) {
      setMaxPage(Number(response.headers.get('x-pagination-pagemax'))-1);
      setItemsAmount(Number(response.headers.get('x-pagination-itemscount')));
    }
    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.length == 0) {
        setTableItemsWithNoData();
      } else {
        setTableItems(dataReceivedCallback(responseData));
        setIsDataZero(false);
      }
      setIsLoading(false);
    } else {
      setTableItemsWithNoData();
      setIsDataZero(true);
      setIsLoading(false);
    }
	};

  const setTableItemsWithNoData = function() {
    let tableItemsNoData = [];
    let obj = {};
    for (let i = 0, n = columnKeys.length; i < n; i++) {
      obj[columnKeys[i][0]] = "No Data";
    }
    obj['__id'] = 0;
    tableItemsNoData.push(obj);
    setTableItems(tableItemsNoData);
  };

  const updatePages = function() {
    let amountOfPages = 4; //NOTE(muamer): Not including the page you're on, must be an even number!
    let availableToRight = maxPage - page;
    if (availableToRight > amountOfPages/2) availableToRight = amountOfPages/2;
    let count = 0, pages = Array(amountOfPages+1);
    for (let i = page - amountOfPages + availableToRight; (maxPage+1) > i; i++) {
      if (i < 0) continue;
      pages[count] = i;
      count++;
      if (count == amountOfPages+1) break;
    }
    setPageNumbers(pages);
  };

  const getTableHeading = function() {
    return (
      <thead>
        <tr>
          {columns.map(column => (
            <th className={column[1]}>{column[0]}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const getTablePagination = function() {
    return (
      <div className="row pagsum">
        <div className="pagination-col col-xl-6">
          <ul className="pagination">
            {addBeforePaginationInBar}
            <li className={'page-item ' + (page < 1 ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(function(prevPage){if (prevPage > 0) return prevPage-1;})}>Previous</Link></li>
            <li className={'page-item ' + (page < 1 ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(0)}>First</Link></li>
            {pageNumbers.map(function(pageNumber) {
              return <li className={'page-item ' + (page == pageNumber ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(pageNumber)}>{pageNumber+1}</Link></li>
            })}
            <li className={'page-item ' + (page >= maxPage ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(maxPage)}>Last</Link></li>
            <li className={'page-item ' + (page >= maxPage ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(function(prevPage){if (prevPage < maxPage) return prevPage+1;})}>Next</Link></li>
            {addToPaginationInBar}
          </ul>
        </div>
        <div className="summaryline col-xl-6" style={{textAlign: "right"}}>
          Displaying
          <b> {pageSize*page+1} {" - "} {pageSize*page+tableItems.length}</b>
          <i> ({tableItems.length}) </i>
          entries out of {itemsAmount}
        </div>
      </div>
    );
  };

  const updateCompleteTable = async function() {
    if (maxItemsLink === null) {
      updateElements();
      updatePages();
    } else {
      updateElements();
      const prevCookie = document.cookie;
      const response = await getApiRequest(maxItemsLink);
      document.cookie = prevCookie;
      if (response.status === 200) {
        const responseData = await response.json();
        setItemsAmount(responseData.count);
        const calculatedMaxPage = Math.floor(responseData.count/pageSize);
        if (maxPage === calculatedMaxPage) updatePages();
        else setMaxPage(calculatedMaxPage);
      }
    }
  };

  useEffect(() => {
    if (tableRefreshCounter != refreshCounter) {
      setTableRefreshCounter(refreshCounter);
      updateCompleteTable();
    }
  }, [refreshCounter]);

  useEffect(async function() {
    if (tableResetCounter != resetCounter) {
      setTableResetCounter(resetCounter);
      if (page == 0) updateCompleteTable();
      else await setPage(0);
    } 
  }, [resetCounter]);

	useEffect(async function() {
    updateCompleteTable();
  }, [page]);

  useEffect(() => {
    updatePages();
  }, [maxPage]);

  if (isLoading == true) {
    return (
      <div>
        <Table responsive hover>
          {getTableHeading()}
        </Table>
        <div className="d-flex justify-content-center">
          <Spinner color="primary" style={{width: "5rem", height: "5rem"}} type="grow"></Spinner>
        </div>
      </div>
    );
  }

  if (checkValues !== null && !isDataZero) {
    var i = -1;
    return (
      <div>
        <Table responsive hover>
          {getTableHeading()}
          <tbody>
          	{tableItems.map(function(item) {
              return <tr key={item.__id}>
                {columnKeys.map(function(columnKey) {
                  if (columnKey[0] !== '__checkbox') return <td className={columnKey[1]}>{item[columnKey[0]]}</td>
                  return (
                    <td className={columnKey[1]}>
                      <FormGroup check>
                        <Label>
                          <Input style={{"top" : "0"}} type="checkbox" checked={checkValues[item.__id].checked} disabled={checkValues[item.__id].disabled} onClick={(e) => checkboxCallback(item.__id)}/>{' '}
                        </Label>
                      </FormGroup>
                    </td>
                  );
                })}
              </tr>
            })}
          </tbody>
        </Table>
        {getTablePagination()}
      </div>
	  );
  }

  return (
    <div>
      <Table responsive hover>
        {getTableHeading()}
        <tbody>
          {tableItems.map(item => (
            <tr key={item.__id}>
              {columnKeys.map(columnKey => (
                <td className={columnKey[1]}>{item[columnKey[0]]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {getTablePagination()}
    </div>
  );
}

export default CustomTable;