const apiURL = '/api/v1/';

const getCookieByName = function(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const checkPasswordRules = function(pass) {
  const re = /^(?=.*\d)(?=.*[!@#_+\\\-'$%^&*])(?=.*[a-z])(?=.*[A-Z]).{14,}$/;
  return re.test(pass);
};

const getApiRequest = async function(URL, headers = false) {
  const requestObject = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getCookieByName("auth_key")
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };
  if (headers !== false) {
    for (let i = 0, n = headers.length; i < n; i++) {
      requestObject.headers[headers[i][0]] = headers[i][1];
    }
  }
	return await fetch(apiURL+URL, requestObject)
  .then(response => {
  	return response;
  });
};

const postApiRequest = async function(Data, URL, headers = false) {
  const requestObject = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getCookieByName("auth_key")
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: Data
  };
  if (headers !== false) {
    for (let i = 0, n = headers.length; i < n; i++) {
      requestObject.headers[headers[i][0]] = headers[i][1];
    }
  }
  return await fetch(apiURL+URL, requestObject)
  .then(response => {
    return response;
  });
};

const patchApiRequest = async function(Data, URL, headers = false) {
  const requestObject = {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getCookieByName("auth_key")
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: Data
  };
  if (headers !== false) {
    for (let i = 0, n = headers.length; i < n; i++) {
      requestObject.headers[headers[i][0]] = headers[i][1];
    }
  }
  return await fetch(apiURL+URL, requestObject)
  .then(response => {
    return response;
  });
};

export {apiURL, checkPasswordRules, getApiRequest, postApiRequest, getCookieByName, patchApiRequest};