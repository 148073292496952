import React, {useState, useEffect, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {Row, Col, Label, Input, Button, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

import CustomTable from '../Utils/CustomTable.js';
import {postApiRequest} from "../Utils/Utils.js";

const PageDOP = function() {
  const [supplier, setSupplier] = useState("");
  const [dopCountPath, setDopCountPath] = useState("dop/count");
  const tableColumns = [
      //Text, Class
    ["Supplier Name", ""],
    ["Document", ""],
    ["Download", ""]
  ];
  const columnKeys = [
      //Object key, Class
    ["bp_name", ""],
    ["description", ""],
    ["__actions", "table-dop-actions-data"]
  ];
  const [dopSearchQuery, setDopSearchQuery] = useState("dop");
  const [dopTableRefreshCounter, setDopTableRefreshCounter] = useState(0);
  const [resetTableCounter, setResetTableCounter] = useState(0);
  const tableActionPdfIcon = <FontAwesomeIcon style={{cursor: "pointer"}} size="lg" icon={faFilePdf} color="red" title="Download PDF"></FontAwesomeIcon>;
  const tableActionPdf = function(dataItem) {
    if (dataItem.dop_link != null) {
      window.open("https://portal.vanleeuwen.com/Portal"+dataItem.dop_link.substring(1));
    }
  };

  const dataReceivedCallback = function(data) {
    for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__actions = [
      <Fragment>
        {data[i].dop_link != null && <span onClick={() => tableActionPdf(data[i])}>{tableActionPdfIcon}</span>}
      </Fragment>
      ]
    }
    return data;
  };

  const searchSubmit = function(evt) {
    let params = "";
    if (supplier !== undefined && supplier.trim() !== "") params += "bp_name=" + encodeURIComponent(supplier) + "&";
    params = params.substring(0, params.length - 1);
    setDopSearchQuery("dop?"+params);
    setDopCountPath("dop/count?"+params);
    setResetTableCounter((value) => (value+1));
  };

  const supplierOnKeyDown = function(evt) {
    if (evt.keyCode == 13) searchSubmit(evt);
  };

  useEffect(() => {
    document.title = "VLBC - DOP";
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <h1 className="page-title">Declaration of Performance</h1>
    </div>
    <Container fluid className="element-box">
      <Row>
        <Col md="6">
          <Label for="supplier">Supplier</Label>
          <Input placeholder="Supplier Name" value={supplier} onChange={e => setSupplier(e.target.value)} onKeyDown={supplierOnKeyDown} />
        </Col>
        <Col md="6" className="content d-flex align-items-end">
          <Button className="mx-auto" color="primary" style={{width: "90%"}} onClick={(evt) => searchSubmit(evt)}>Search</Button>
        </Col>
      </Row>
    </Container>
    <Container fluid className="element-box">
      <CustomTable resetCounter={resetTableCounter} maxItemsLink={dopCountPath} refreshCounter={dopTableRefreshCounter} visible="false" columns={tableColumns} columnKeys={columnKeys}
        apiPath={dopSearchQuery} dataReceivedCallback={dataReceivedCallback} />
    </Container>
  </Fragment>
  );
};

export default PageDOP;