const PageURL = {
	Login : "/login",
	Home : "/",
	DOP : "/dop",
	Certificates : "/certificates",
	myAccount: "/my-account",
	customerAccounts: "/customer-accounts",
	userAccounts: "/user-accounts",
	logOut: "/logout",
	forgotPassword: "/forgot-password/:token"
};

export default PageURL;